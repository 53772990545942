<template>
  <div class="compact-view-toggle-button px-1">
    <v-btn icon @click="toggleCompactView">
      <ToolTip :icon="icon" :description="description" custom-class="tooltip-wide" />
    </v-btn>
  </div>
</template>

<script>
import ToolTip from '@/components/others/ToolTip'
export default {
  components: { ToolTip },
  computed: {
    isCompactView() {
      return this.$store.getters['compact_view/isCompactView']
    },
    icon() {
      return this.isCompactView ? 'mdi-arrow-expand-vertical' : 'mdi-arrow-collapse-vertical'
    },
    description() {
      return this.isCompactView
        ? 'Switch to default view<br />&mdash; more spacing, easier to read.'
        : 'Switch to a more compact view<br />&mdash; less spacing, more data.'
    }
  },
  methods: {
    toggleCompactView() {
      this.$store.dispatch('compact_view/toggleCompactView')
    }
  }
}
</script>
