<template>
  <div class="grid">
    <v-form ref="form" v-if="enableFilters" v-model="validFilters" lazy-validation :name="formName" v-on:submit.prevent>
      <div class="filters mb-4">
        <v-row>
          <!-- filter slot -->
          <slot name="filters"></slot>
        </v-row>
      </div>
    </v-form>

    <!-- middle slot -->
    <slot name="middle" :items="data.data"></slot>

    <v-card>
      <v-card-text class="data-table pa-0">
        <v-data-table
          :headers="visibleHeaders"
          :hide-default-footer="hideDefaultFooter"
          :disable-pagination="!showPagination"
          :items="data.data"
          :loading="loading"
          :page.sync="page"
          :server-items-length="data.total === null ? paginationDefaultTotal : data.total"
          mobile-breakpoint="0"
          :single-select="singleSelect"
          :show-select="showSelect"
          :item-class="itemClass"
          v-model="selected"
          :item-key="itemKey"
          :items-per-page="perPage"
          :footer-props="{
            itemsPerPageOptions: perPageOptions,
            itemsPerPageText: this.$t('grid.per_page'),
            showFirstLastPage: paginationShowFirstLastPage
          }"
          :sort-by.sync="sortByGrid"
          :sort-desc.sync="sortDescGrid"
          @update:items-per-page="perPage = $event"
        >
          <template v-slot:[`footer.page-text`]="items" v-if="!paginationShowTotals">
            <ToolTip v-if="items.pageStop > 0" :text="`${items.pageStart}-${items.pageStop}`" :description="$t('grid.no_totals')" />
          </template>
          <template v-slot:top>
            <div class="grid-toolbar d-flex flex-wrap px-2 py-2">
              <slot name="toolbarSelect" />
              <div class="toolbar-actions d-inline-flex ml-auto">
                <DownloadLink
                  v-if="downloadLinkArguments && downloadLinkArguments.url"
                  :url="downloadLinkProps.url"
                  :link-text="downloadLinkProps.linkText"
                  :file-name="downloadLinkProps.fileName"
                  :link-as-button="downloadLinkProps.linkAsButton"
                />
                <CompactViewToggleButton />
              </div>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              v-if="isBulkEnabled"
              color="primary"
              :ripple="false"
              v-bind="props"
              v-on="on"
              :value="true"
              :disabled="true"
              :indeterminate="false"
            />
            <v-simple-checkbox v-else color="primary" :ripple="false" v-bind="props" v-on="on" />
          </template>
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox v-if="isBulkEnabled" color="primary" :ripple="false" @input="select($event)" :value="true" :disabled="true" />
            <v-simple-checkbox v-else color="primary" :ripple="false" @input="select($event)" :value="isSelected" />
          </template>
          <template v-for="(h, index) in headers" v-slot:[`header.${h.value}`]>
            <span :key="index">{{ h.text }} <HelpInfo v-if="h.description" :description="h.description"/></span>
          </template>
          <!-- forward data table slots -->
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ErrorHelper from '@/error/error'
import { rules } from '@/validation/rules'
import ToolTip from '@/components/others/ToolTip'
import HelpInfo from '@/components/others/HelpInfo'
import DownloadLink from '@/components/others/DownloadLink'
import CompactViewToggleButton from '@/components/others/CompactViewToggleButton'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import Logger from '@/util/Logger'

export default {
  mixins: [GridBaseMixin],
  components: {
    ToolTip,
    HelpInfo,
    DownloadLink,
    CompactViewToggleButton
  },
  props: {
    serverSidePagination: {
      type: Boolean,
      default: true
    },
    headers: Array,
    itemClass: {
      type: Function,
      default: () => null
    },
    enablePagination: {
      type: Boolean,
      default: false
    },
    enableHistory: {
      type: Boolean,
      default: true
    },
    paginationShowFirstLastPage: {
      type: Boolean,
      default: true
    },
    paginationShowTotals: {
      type: Boolean,
      default: true
    },
    paginationDefaultTotal: {
      type: Number,
      default: 99999999
    },
    enableFilters: {
      type: Boolean,
      default: true
    },
    perPageOptions: {
      type: Array,
      default: function() {
        return [10, 20, 30, 50, 100]
      }
    },
    enabledColumns: {
      type: Array,
      default: function() {
        // by default we show all columns where a header field is defined
        return this.headers.map(headerField => headerField.value)
      }
    },
    defaultPerPage: {
      type: Number,
      default: 30
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: true
    },
    itemKey: {
      type: String,
      default: null
    },
    additionalApiArguments: Object,
    dataStoreAction: String,
    downloadLinkArguments: Object,
    formName: {
      String,
      default: 'filter-form'
    },
    defaultSortBy: {
      type: Array,
      default: () => []
    },
    defaultSortDesc: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let data = {
      clientSidePage: 1,
      clientSidePerPage: 20,
      data: {
        total: 0
      },
      selected: [],
      isBulkEnabled: false,
      rules: { ...rules },
      apiRequestsCount: 0,
      sortByGrid: this.defaultSortBy,
      sortDescGrid: this.defaultSortDesc,
      loading: true,
      validFilters: !this.enableFilters,
      apiArgs: {
        // persistent arguments keep the current active page since the amount of results does not change
        persistent: {
          sort: this.buildArgument('sort', null, this.getSortValue(this.defaultSortDesc, this.defaultSortBy))
        },

        // restarting arguments will trigger a restarting from page 1 because the amount of result will be different
        restarting: {}
      },
      apiArgsPushedToRoute: false,
      queuedApiCalls: []
    }

    if (this.serverSidePagination) {
      data.apiArgs.persistent.page = this.buildArgument('page', () => this.enablePagination, 1)
      data.apiArgs.restarting.per_page = this.buildArgument('per_page', () => this.enablePagination, this.defaultPerPage)
    }

    return data
  },
  watch: {
    selected(val, oldVal) {
      if (val.length > 500) {
        this.$nextTick(() => {
          this.selected = oldVal
          this.$store.dispatch('snackbar/showWarningMessage', { message: this.$t('grid.maximum_selected_domains') })
        })
      }
    }
  }, // Watchers are defined in the mounted hook because handler changed was triggered when additionalArgs where merged
  computed: {
    perPage: {
      get() {
        if (this.serverSidePagination) {
          return this.apiArgs.restarting.per_page.value
        }

        return this.clientSidePerPage
      },
      set(newValue) {
        if (this.serverSidePagination) {
          this.apiArgs.restarting.per_page.value = newValue
        }

        this.clientSidePerPage = newValue
      }
    },
    page: {
      get() {
        if (this.serverSidePagination) {
          return this.apiArgs.persistent.page.value
        }

        return this.clientSidePage
      },
      set(newValue) {
        if (this.serverSidePagination) {
          this.apiArgs.persistent.page.value = newValue
        }

        this.clientSidePage = newValue
      }
    },
    pages() {
      return Math.ceil(this.data.total / this.apiArgs.restarting.per_page.value)
    },
    showPagination() {
      let hasItems = this.data.data != null && this.data.data.length > 0
      return this.enablePagination && hasItems
    },
    downloadLinkProps() {
      let url = this.downloadLinkArguments.url
      if (this.downloadLinkArguments.apiArgumentsToUse) {
        let apiArguments = this.getApiArguments()
        for (let argument in apiArguments) {
          if (this.downloadLinkArguments.apiArgumentsToUse.includes(argument)) {
            url += `&${argument}=${apiArguments[argument]}`
          }
        }
      }
      return {
        url: url,
        linkText: this.downloadLinkArguments.linkText,
        linkAsButton: this.downloadLinkArguments.linkAsButton,
        fileName: typeof this.downloadLinkArguments.fileName !== 'undefined' ? this.downloadLinkArguments.fileName : 'export.csv'
      }
    },
    visibleHeaders() {
      return this.headers.filter(header => this.enabledColumns.includes(header.value))
    },
    hideDefaultFooter() {
      return !this.showPagination
    }
  },
  methods: {
    getApiArguments() {
      let apiArguments = {}
      this.callArgumentProvidersForAllArguments(apiArguments, this.apiArgs.persistent)
      this.callArgumentProvidersForAllArguments(apiArguments, this.apiArgs.restarting)
      Object.keys(apiArguments).forEach(key => apiArguments[key] === null && delete apiArguments[key])
      return apiArguments
    },
    callArgumentProvidersForAllArguments(apiArguments, argumentsToBuild) {
      for (const argument of Object.values(argumentsToBuild)) {
        if (typeof argument.argumentProvider === 'function') {
          argument.argumentProvider(apiArguments, argument.value)
        }
      }
      return apiArguments
    },
    /**
     * Retrieves the data from the api, if no loading is in progress
     */
    getDataIfNotLoading(goToFirstPage = false) {
      if (this.loading) {
        this.queuedApiCalls.push(this.apiArgs)
        return
      }
      if (goToFirstPage) {
        this.page = 1
      }
      return this.getDataFromApi()
    },

    /**
     * Retrieves the data from the api
     */
    getDataFromApi() {
      // if a field has validation errors, we don't to a request
      if (!this.validFilters) {
        ErrorHelper.show(this.$t('errors.rules'))
        return
      }

      this.loading = true
      this.$store
        .dispatch(this.dataStoreAction, this.getApiArguments())
        .then(response => {
          this.apiRequestsCount++
          this.data = response.data
          this.buildGridSort()
          this.$parent.$emit('api_data_retrieved', this.data)
          this.loading = false
          if (response.debug && response.debug.querylog) {
            for (let i in response.debug.querylog) {
              Logger.log('Query: ' + response.debug.querylog[i].query)
              Logger.log('Time: ' + response.debug.querylog[i].time)
            }
          }

          if (this.queuedApiCalls.length > 0) {
            this.apiArgs = this.queuedApiCalls.pop()
            this.getDataFromApi()
          }
        })
        .catch(error => {
          this.$parent.$emit('api_error')
          Logger.log('Error catched ', { error })
          this.loading = false
        })
    },
    buildSortForApi() {
      let sortValue = this.getSortValue(this.sortDescGrid, this.sortByGrid)
      if (this.apiArgs.persistent.sort.value === sortValue) {
        return
      }

      this.apiArgs.persistent.sort.value = sortValue
    },
    buildGridSort() {
      let sort = this.queryParamOrDefaultValue('sort', '')
      if (sort === '') {
        return
      }

      if (String(sort).slice(0, 1) === '-') {
        this.sortDescGrid[0] = true
        this.sortByGrid[0] = String(sort).slice(1)
      } else {
        this.sortDescGrid[0] = false
        this.sortByGrid[0] = sort
      }
    },
    getSortValue(sortDesc, sortBy) {
      if (sortDesc.length === 0 || sortBy.length === 0) {
        return null
      }
      return sortDesc[0] ? '-' + sortBy[0] : sortBy[0]
    },
    updateQueryParams() {
      this.apiArgsPushedToRoute = false
      if (!this.apiArgsPushedToRoute && this.apiRequestsCount >= 1) {
        this.apiArgsPushedToRoute = true
        let apiArgs = [...Object.entries(this.apiArgs.persistent), ...Object.entries(this.apiArgs.restarting)]
        this.$router.push({ query: this.filterApiArgsByCheck(apiArgs) }).catch(error => {
          if (error.name !== 'NavigationDuplicated') {
            // to prevent errors on page reload
            throw error
          }
        })
      }
    }
  },
  created() {
    if (this.additionalApiArguments && this.additionalApiArguments.persistent) {
      this.apiArgs.persistent = { ...this.apiArgs.persistent, ...this.additionalApiArguments.persistent }
    }
    if (this.additionalApiArguments && this.additionalApiArguments.restarting) {
      this.apiArgs.restarting = { ...this.apiArgs.restarting, ...this.additionalApiArguments.restarting }
    }
  },
  mounted() {
    this.$watch(
      'apiArgs.persistent',
      function() {
        this.getDataIfNotLoading(false)
        this.updateQueryParams()
      },
      { deep: true }
    )

    this.$watch(
      'apiArgs.restarting',
      function() {
        this.getDataIfNotLoading(true)
        this.updateQueryParams()
      },
      { deep: true }
    )

    this.$watch('sortByGrid', this.buildSortForApi, { deep: true })
    this.$watch('sortDescGrid', this.buildSortForApi, { deep: true })

    window.addEventListener('popstate', () => {
      if (this.enableHistory) {
        this.$router.go(0)
      }
    })
    this.getDataFromApi()
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .grid
  .filters .filter-search
    .v-input__slot
      border-radius: 34px !important

  th
    height: 52px
    font-size: 14px
    font-weight: 700
    color: $primary-text
    white-space: nowrap
  td
    height: 52px
    white-space: nowrap

  .column-domainidn
    width: 350px
    max-width: 350px
    overflow: hidden
    text-overflow: ellipsis
  .column-folder
    width: 200px
    max-width: 200px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .domain
    display: block
  .folder
    display: block

  .v-simple-checkbox
    .v-input--selection-controls__input
      margin: 0
      .v-icon:hover
        color: $primary

  .v-data-footer__select .v-input__slot
    padding-left: 12px

  @media (max-width: 600px)
    .grid-toolbar
      .toolbar-select
        order: 1
      .toolbar-select-actions
        order: 3
        flex-basis: 100%
        justify-content: center
      .toolbar-actions
        order: 2

#app #domain-index .grid .v-data-table-header th:nth-child(2)
  width: 1px
  min-width: 1px

#app #folder-index .grid .v-data-table-header th:nth-child(1)
    width: 1px
    min-width: 1px

#app.compact .grid
  th, td
    height: 34px
</style>
