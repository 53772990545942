export const rules = {
  required: value => !!value,
  date: value => {
    const pattern = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/
    return pattern.test(value)
  },
  int: value => {
    return Number.isInteger(value)
  },
  lessLinesThen: (value, limit) => {
    return (value.match(/\n/g) || '').length + 1 <= limit
  },
  optionalPositiveInteger: value => {
    if (!value) {
      return true
    }

    return rules.onlyNumbers(value) && parseInt(value) > 0
  },
  email: value => {
    const pattern = /.+@.+\..+/
    return pattern.test(value)
  },
  url: value => {
    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})/gi
    return pattern.test(value)
  },
  onlyNumbers: value => {
    const pattern = /^\d+$/
    return pattern.test(value)
  },
  onlyTextFiles: file => {
    if (file === null) {
      return true
    }

    const extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()

    return extension === 'txt'
  },
  maxFiles: files => {
    if (files === null) {
      return true
    }

    return files.length <= 5
  }
}
