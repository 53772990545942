let Convert = {
  parseString(value) {
    if (typeof value !== 'string' && !(value instanceof String)) {
      return value
    } else if (typeof value === 'undefined' || value === 'undefined' || value === 'null') {
      return null
    } else if (value.trim() === '') {
      return ''
    } else if (value === 'true' || value === 'false') {
      return value === 'true'
    } else if (!isNaN(Number(value))) {
      return Number(value)
    }
    return value
  }
}

export default Convert
