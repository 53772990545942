import Convert from '@/util/Convert'
class RouteQueryHelper {
  __query = null

  constructor(query) {
    this.__query = query
  }

  static fromQuery(query) {
    return new RouteQueryHelper({ ...query })
  }

  remove(key) {
    delete this.__query[key]
    return this
  }

  removeMany(keys) {
    keys.forEach(key => {
      this.remove(key)
    })
    return this
  }

  removeExceptMany(keysToKeep) {
    let keysToRemove = Object.keys(this.__query).filter(key => !keysToKeep.includes(key))
    this.removeMany(keysToRemove)
    return this
  }

  has(key) {
    return this.__query[key] !== undefined
  }

  get(key, defaultValue = null, getRawValue = false) {
    if (!this.has(key)) {
      return defaultValue
    }
    return getRawValue ? this.__query[key] : Convert.parseString(this.__query[key])
  }

  getAll(getRawValue = false) {
    if (getRawValue) {
      return this.__query
    }
    let parsedQuery = {}
    Object.keys(this.__query).forEach(key => {
      parsedQuery[key] = Convert.parseString(this.__query[key])
    })
    return parsedQuery
  }
}

export default RouteQueryHelper
