import Logger from '@/util/Logger'
import Convert from '@/util/Convert'
import RouteQueryHelper from '@/util/RouteQueryHelper'

let GridBaseMixin = {
  routeQueryHelper: null,
  methods: {
    getGridHasResults() {
      return this.data.gridHasResults ?? false
    },
    queryParamOrDefaultValue(key, defaultValue, query) {
      return RouteQueryHelper.fromQuery(query || this.$route.query).get(key, defaultValue)
    },
    filterApiArgsByCheck(apiArg) {
      let checkedApiArgs = {}
      for (const [key, valueObj] of apiArg) {
        let value = Convert.parseString(valueObj.value)
        if (value === null) {
          continue
        }
        if (typeof valueObj.check === 'function' && !valueObj.check(value)) {
          continue
        }

        checkedApiArgs[key] = value
      }
      return checkedApiArgs
    },
    buildArgument(argumentName, check, defaultValue = null) {
      return {
        value: this.queryParamOrDefaultValue(argumentName, defaultValue),
        check: check,
        argumentProvider: (apiArguments, argumentValue) => {
          // default check is just if argument exists
          if (check === null || typeof check === 'undefined') {
            check = value => value
          }
          if (check(argumentValue)) {
            apiArguments[argumentName] = argumentValue
          }
        }
      }
    },
    onRefreshData() {
      this.$refs.grid.getDataFromApi()
    }
  },
  mounted() {
    this.$watch('$refs.grid.data.data', function(newValue) {
      this.data.gridHasResults = newValue.length > 0
    })
    this.$on('api_data_retrieved', data => {
      this.data.data = data.data
    })

    this.$on('api_error', () => {
      Logger.log('Stats api error cached')
    })
  },
  data() {
    return {
      data: {
        gridHasResults: false,
        total: 0
      }
    }
  }
}

export default GridBaseMixin
