var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[(_vm.enableFilters)?_c('v-form',{ref:"form",attrs:{"lazy-validation":"","name":_vm.formName},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.validFilters),callback:function ($$v) {_vm.validFilters=$$v},expression:"validFilters"}},[_c('div',{staticClass:"filters mb-4"},[_c('v-row',[_vm._t("filters")],2)],1)]):_vm._e(),_vm._t("middle",null,{"items":_vm.data.data}),_c('v-card',[_c('v-card-text',{staticClass:"data-table pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.visibleHeaders,"hide-default-footer":_vm.hideDefaultFooter,"disable-pagination":!_vm.showPagination,"items":_vm.data.data,"loading":_vm.loading,"page":_vm.page,"server-items-length":_vm.data.total === null ? _vm.paginationDefaultTotal : _vm.data.total,"mobile-breakpoint":"0","single-select":_vm.singleSelect,"show-select":_vm.showSelect,"item-class":_vm.itemClass,"item-key":_vm.itemKey,"items-per-page":_vm.perPage,"footer-props":{
          itemsPerPageOptions: _vm.perPageOptions,
          itemsPerPageText: this.$t('grid.per_page'),
          showFirstLastPage: _vm.paginationShowFirstLastPage
        },"sort-by":_vm.sortByGrid,"sort-desc":_vm.sortDescGrid},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortByGrid=$event},"update:sort-by":function($event){_vm.sortByGrid=$event},"update:sortDesc":function($event){_vm.sortDescGrid=$event},"update:sort-desc":function($event){_vm.sortDescGrid=$event},"update:items-per-page":function($event){_vm.perPage = $event}},scopedSlots:_vm._u([(!_vm.paginationShowTotals)?{key:"footer.page-text",fn:function(items){return [(items.pageStop > 0)?_c('ToolTip',{attrs:{"text":((items.pageStart) + "-" + (items.pageStop)),"description":_vm.$t('grid.no_totals')}}):_vm._e()]}}:null,{key:"top",fn:function(){return [_c('div',{staticClass:"grid-toolbar d-flex flex-wrap px-2 py-2"},[_vm._t("toolbarSelect"),_c('div',{staticClass:"toolbar-actions d-inline-flex ml-auto"},[(_vm.downloadLinkArguments && _vm.downloadLinkArguments.url)?_c('DownloadLink',{attrs:{"url":_vm.downloadLinkProps.url,"link-text":_vm.downloadLinkProps.linkText,"file-name":_vm.downloadLinkProps.fileName,"link-as-button":_vm.downloadLinkProps.linkAsButton}}):_vm._e(),_c('CompactViewToggleButton')],1)],2),_c('v-divider')]},proxy:true},{key:"header.data-table-select",fn:function(ref){
        var props = ref.props;
        var on = ref.on;
return [(_vm.isBulkEnabled)?_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"primary","ripple":false,"value":true,"disabled":true,"indeterminate":false}},'v-simple-checkbox',props,false),on)):_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"primary","ripple":false}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
        var isSelected = ref.isSelected;
        var select = ref.select;
return [(_vm.isBulkEnabled)?_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":true,"disabled":true},on:{"input":function($event){return select($event)}}}):_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},_vm._l((_vm.headers),function(h,index){return {key:("header." + (h.value)),fn:function(){return [_c('span',{key:index},[_vm._v(_vm._s(h.text)+" "),(h.description)?_c('HelpInfo',{attrs:{"description":h.description}}):_vm._e()],1)]},proxy:true}}),_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }